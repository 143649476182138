.sso-app-icons {
    display: block;
    border-radius: 3px;
    line-height: 34px;
    text-align: center;
    padding: 15px 0 9px;
    display: block;
    border: 1px solid transparent;
    color: var(--#{$prefix}gray-600);
    margin: 15px 40px;

    img {
        height: 50px;
    }

    span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &:hover {
        border-color: var(--#{$prefix}border-color);
    }
}